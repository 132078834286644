<template>
    <b-modal ref="modal-change" size="xl" v-model="computedShow" no-close-on-backdrop hide-footer
        :title="trans('p-flexworker-information', 305)">
        <b-card v-if="loading">
            <div class="d-flex justify-content-center">
                <app-refresh :loading="loading" :refresh="refresh"></app-refresh>
            </div>
        </b-card>

        <b-card v-if="!loading">

            <document-file-preview :itemData.sync="preview.item_data" v-if="preview.show" :show.sync="preview.show" />

            <b-row>
                <b-col :cols="7" class="mx-3">
                    <b-row class="mb-2 d-flex flex-column app-labels-local" v-for="(item, index) in info_data"
                        :key="index">
                        <div>
                            <b-row class="mb-1 d-flex align-items-start app-labels-local">
                                <b-col cols="4">
                                    <b v-if="!item.custom">{{ item.label }}</b>
                                    <b v-else-if="item.custom && item.value == 'lrza' && data.lrza == -1"></b>
                                    <b v-else>{{ item.label }}</b>
                                </b-col>
                                <b-col cols="8">
                                    <div v-if="!item.custom">
                                        {{ data[item.value] ? data[item.value] : '-' }}
                                    </div>
                                    <div v-else>
                                        <b-row v-if="item.value == 'phone'">
                                            <b-col v-if="data.phone || data.phone_number">
                                                {{ data.phone_prefix }} {{ data.phone_number }}
                                            </b-col>
                                            <b-col v-else>
                                                -
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="item.value == 'kvk_data_main'">
                                            <b-col v-if="data.kvk_data_main">
                                                <span>{{ JSON.parse(data.kvk_data_main).kvk_number }}</span> <br>
                                                <span>{{ JSON.parse(data.kvk_data_main).trade_name + ', ' +
                                                    JSON.parse(data.kvk_data_main).city + ' ' +
                                                    JSON.parse(data.kvk_data_main).street + ' ' +
                                                    JSON.parse(data.kvk_data_main).house_number }}</span>
                                            </b-col>
                                            <b-col v-else>
                                                -
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="item.value == 'big_number'">
                                            <b-col v-if="data.big_number">
                                                <span>{{ data.big_number }}</span> <br>
                                                <span>{{ JSON.parse(data.big_data_main).mailing_name ?
                                                    JSON.parse(data.big_data_main).mailing_name : '' }}</span>
                                            </b-col>
                                            <b-col v-else>
                                                -
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="item.value == 'lrza' && data.lrza != -1">
                                            <b-col v-if="data.lrza == 1">
                                                <span>Ja</span>
                                            </b-col>
                                            <b-col v-else>
                                                <span>Nee</span>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-row>
                </b-col>
                <b-col class="mx-3">
                    <b-row class="mb-2 d-flex flex-column app-labels-local" v-if="userPlacement == 1">
                        <div>
                            <b>Documenten</b>
                        </div>
                        <div
                            v-if="data.ids_document_data_main && JSON.parse(data.ids_document_data_main)[0].id != null">
                            <div v-for="(doc, i) in JSON.parse(data.ids_document_data_main)" :key="i"
                                class="m-1 p-0 download-document-local d-flex align-items-center">
                                <div @click="downloadFile(doc, i)">
                                    <b-icon v-if="downloadingFile != i" icon="file-earmark" aria-hidden="true" class="m-1 icon-size"></b-icon>
                                    <b-spinner v-else style="width:16px; height:16px; margin-left:4px; margin-right: 4px" small></b-spinner>
                                    <span class="ml-1">
                                        {{ doc.document_name ? doc.document_name : doc.file_name_user }}{{ [10, 3].includes(doc.id_document_const_type) ? ` ${doc.entry_date}` : '' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            -
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>

        <offers-shifts-info-shifts-list class="mt-3"
            :id_person_data_main="cArgs.id_person_data_main"></offers-shifts-info-shifts-list>
    </b-modal>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import AppAlerts from '@core/scripts/AppAlerts';
import OffersShiftsInfoShiftsList from "./OffersShiftsInfoShiftsList.vue";
import DocumentFilePreview from './DocumentFilePreview.vue';

export default {

    components: {
        OffersShiftsInfoShiftsList,
        DocumentFilePreview
    },

    props: {
        show: {
            type: Boolean,
            required: true
        },
        args: {
            type: Object,
            required: true
        },
        result: {
            type: Number,
            required: true
        },
        return: {
            type: Object,
            required: true
        },
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) },
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    watch: {

    },

    created() {
        this.getInfo();
    },

    data() {
        return {
            userPlacement: JSON.parse(localStorage.getItem('user-portal')).placement,
            alert_class: new AppAlerts(),
            loading: false,
            asLoading: false,
            refresh: 0,
            data: {},
            info_data: [
                { label: "Naam", value: "person_name" },
                { label: "E-mail", value: "email" },
                { label: "Telefoonnummer", value: "phone", custom: true },
                { label: "Geboortedatum", value: "birth_date" },
                { label: "Functies", value: "function_names" },
                { label: "Kwalificaties", value: "qualification_names" },
                { label: "KVK nummer", value: "kvk_data_main", custom: true },
                { label: "BIG nummer", value: "big_number", custom: true },
                { label: "Ingeschreven in register LRZa", value: "lrza", custom: true },
            ],
            activeTab: 0,
            preview: {
                show: false,
                item_data: {
                    base_64: null,
                    url: null,
                    type: null,
                    title: null
                }
            },
            downloadingFile: null
        }
    },

    methods: {
        getInfo() {
            this.loading = true;
            axios.post("shifts/getInfo", {
                id_person_data_main: this.cArgs.id_person_data_main,
                id_shift_data_main: this.cArgs.id_shift_data_main
            }).then(response => {
                this.data = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
                this.refresh++;
            });
        },

        downloadFile(item, index) {
            if(this.downloadingFile == null){
                this.downloadingFile = index;
                axios.post("shifts/downloadFile", {
                    id_document_data_main: item.id,
                    id_person_data_main: this.data.id_person_data_main
                }).then(response => {
                    const validTypes = ['pdf', 'png', 'jpeg', 'jpg'];
                    if(response.data.type == 'docx' || response.data.type == 'doc'){
                        this.preview.item_data.url = response.data.url;
                        this.preview.item_data.type = response.data.type;
                        this.preview.item_data.title = item.document_name;
                        this.preview.show = true;
                    }
                    else if (validTypes.includes(response.data.type.toLowerCase())) {
                        this.preview.item_data.base_64 = response.data.base64;
                        this.preview.item_data.type = response.data.type;
                        this.preview.item_data.title = item.document_name;
                        this.preview.show = true;
                    } else {
                        this.alert_class.openAlertWarning(this.trans('file-not-found', 281)).then(res => { })
                    }
                    this.downloadingFile = null;
                }).catch(error => {
                    console.error(`error during request: ${error}`);
                    this.downloadingFile = null;
                    this.alert_class.openAlertWarning(this.trans('file-not-found', 281)).then(res => { })
                });
            }
            
        },

        closeModal() {
            this.computedShow = false;
        }
    }

}
</script>
<style scoped>
.app-labels-local {
    font-size: 12.8px;
    flex-wrap: nowrap !important;
}

.icon-size {
    font-size: 1rem;
    line-height: 1;
}

.download-document-local {
    min-height: 1.5rem;
}

.download-document-local:hover {
    cursor: pointer;
    text-decoration: underline;
}
</style>