<template>
    <div>
        <b-sidebar 
                id="sidebar-backdrop" 
                title="Filters" 
                backdrop-variant="dark"
                backdrop 
                shadow 
                v-if="sidebar_open"
                class="app-local-sidebar-width"
                >

            <div class="px-3 py-2">
                <b-form-group>

                    <app-row-left-label>
                        <app-input type="text" :label="trans('search',280)" v-model="sidebar_filters.search" :lazy="true" :placeholder="trans('search',280)" size="sm" />
                    </app-row-left-label>

                    <hr class="mt-2 mb-1" />

                    <app-row-left-label>
                        <label class="app-input-top-label">{{trans('status',283)}}</label>
                        <b-button-group  size="sm" class="d-flex">
                            <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': sidebar_filters.type !== 1, 'app-list-button-selected':  sidebar_filters.type === 1 }" @click="sidebar_filters.type = 1">{{trans('open',282)}}</b-btn>
                            <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': sidebar_filters.type !== 2, 'app-list-button-selected':  sidebar_filters.type === 2 }" @click="sidebar_filters.type = 2">{{trans('sh-in-progress', 292)}}</b-btn>
                            <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': sidebar_filters.type !== 3, 'app-list-button-selected':  sidebar_filters.type === 3 }" @click="sidebar_filters.type = 3">{{trans('sh-accept',292)}}</b-btn>
                            <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': sidebar_filters.type !== 4, 'app-list-button-selected':  sidebar_filters.type === 4 }" @click="sidebar_filters.type = 4">{{trans('sh-confirmed',294)}}</b-btn>
                            <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': sidebar_filters.type !== 5, 'app-list-button-selected':  sidebar_filters.type === 5 }" @click="sidebar_filters.type = 5">{{trans('sh-completed',292)}}</b-btn>
                            <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': sidebar_filters.type !== 6, 'app-list-button-selected':  sidebar_filters.type === 6 }" @click="sidebar_filters.type = 6">{{trans('sh-all', 292)}}</b-btn>
                        </b-button-group>
                    </app-row-left-label>

                    <hr class="mt-2 mb-1" />

                    <app-row-left-label>
                        <label class="app-input-top-label">{{trans('pl-date',288)}}</label>
                        <app-date-week-selector-sidebar :choosedDate.sync="sidebar_filters.offer_date" :listType.sync="sidebar_filters.date_type" :changeArrows="true" :weekDates.sync="sidebar_filters.offer_week" :key="refresh" :clearable="true" :onlyWeek="false"/>
                    </app-row-left-label>



                    <app-row-left-label class="app-local-margin">
                        <app-button type="secondary" style="width: inherit;" @click="setSidebarFilters">{{trans('save',282)}}</app-button>
                    </app-row-left-label>
                </b-form-group>
            </div>

        </b-sidebar>
        <div class="mt-3 pt-3">
            <b-row>
                <b-col cols="12">

                    <b-card>
                        <b-overlay :show="loading" :opacity="0.6">

                            <b-row class="app-card-header app-local-header-height">
                                <b-col start>
                                    <div class="app-card-tittle app-local-title-height d-flex align-items-center">
                                        <div>{{trans('services',280)}} <span v-if="!loading">({{ items.total }})</span></div>
                                    </div>
                                </b-col>

                                <b-col cols="auto" class="app-local-smart">
                                    <div class="sidenav-toggler h-100 d-flex align-items-center"  v-b-toggle.sidebar-backdrop @click="openFilters">
                                        <div class="sidenav-toggler-inner">
                                            <i class="sidenav-toggler-line"></i>
                                            <i class="sidenav-toggler-line"></i>
                                            <i class="sidenav-toggler-line"></i>
                                        </div>
                                    </div>
                                </b-col>

                                <b-col cols="auto" class="app-local-big pl-0" end>
                                    <b-form-input type="text" v-model="filters.search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': filters.search.length > 0}" :placeholder="trans('search',280)" size="sm" />
                                </b-col>


                                <b-col cols="auto" class="pl-0 app-local-big" end>
                                    <app-date-week-selector :choosedDate.sync="filters.offer_date" :listType.sync="filters.date_type" :changeArrows="true" :weekDates.sync="filters.offer_week" :key="refresh" :clearable="true" :onlyWeek="false"/>
                                </b-col>

                                <b-col cols="auto" end class="pl-0 app-local-big">
                                    <b-button-group  size="sm">
                                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 1, 'app-list-button-selected':  filters.type === 1 }" @click="filters.type = 1">{{trans('open',282)}}</b-btn>
                                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 2, 'app-list-button-selected':  filters.type === 2 }" @click="filters.type = 2">{{trans('sh-in-progress', 292)}}</b-btn>
                                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 3, 'app-list-button-selected':  filters.type === 3 }" @click="filters.type = 3">{{trans('sh-accept',292)}}</b-btn>
                                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 4, 'app-list-button-selected':  filters.type === 4 }" @click="filters.type = 4">{{trans('sh-confirmed',294)}}</b-btn>
                                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 5, 'app-list-button-selected':  filters.type === 5 }" @click="filters.type = 5">{{trans('sh-completed',292)}}</b-btn>
                                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 6, 'app-list-button-selected':  filters.type === 6 }" @click="filters.type = 6">{{trans('sh-all', 292)}}</b-btn>
                                    </b-button-group>
                                </b-col>

                                <b-col cols="auto" class="pl-0 d-flex align-items-center">
                                    <div>
                                        <app-refresh :refresh.sync="refresh" :loading.sync="loading"/>
                                    </div>
                                </b-col>
                            </b-row>

                            <app-view-table :isTopBar="false" :filters="filters" :items="items" :fields="fields" resizeableColumns resizeMinWidth :key="refreshTable" :customColumns="custom_columns" :customColumnsHeaders="custom_headers" :isDropdownMenu="![3,4].includes(filters.type)" menuCondition="![5, 6, 9, 18].includes(item.id_shift_const_status) && !([15].includes(item.id_shift_const_status) && item.applicant_cancel == 0 )" v-on:onRowClicked="openShiftInfo">
                                <!-- @onRowClicked="item=>$set(item, '_showDetails', (!item._showDetails))" -->

                                <template v-slot:head_selected>
                                    <app-check-box class="pb-2 pl-2" v-model="selected_all_rows" @change="selectAll" v-if="filters.type != 3"/>
                                </template>

                                <template v-slot:selected="cell">
                                    <app-check-box class="pl-2" v-model="cell.data.item.selected" @change="select($event)" v-if="filters.type != 3" />
                                </template>

                                <template v-slot:qualifications="cell">
                                    <offers-shifts-qualifications-relations :dotColor="cell.data.item.ids_qualification_const_type && JSON.parse(cell.data.item.ids_qualification_const_type).length != 0 ? cell.data.item.person_qualification_compatibility : 2" :idPersonDataMain="cell.data.item.id_person_data_main" :idsShiftDataMain="[cell.data.item.id_shift_data_main]" :ids_qualification_const_type="JSON.parse(cell.data.item.ids_qualification_const_type)"/>
                                </template>

                                <template v-slot:shift_function_name="cell">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="app-local-daytime  app-ellips" :id="'popover-target-applicant_function_additional'+cell.data.item.id_shift_data_main">{{ cell.data.item.shift_function_name}}</div> 
                                        <b-icon icon="info-circle-fill" v-if="cell.data.item.id_shift_ref_applicants != null && cell.data.item.id_function_data_main != cell.data.item.id_function_data_applicant" :id="'popover-target-applicant_function'+cell.data.item.id_shift_data_main" class="app-cursor-pointer ml-auto" style="min-width: 17px; min-height: 17px;" />
                                    </div>
                                    <b-popover :target="'popover-target-applicant_function'+cell.data.item.id_shift_data_main" triggers="hover" no-fade placement="bottomright" v-if="cell.data.item.id_shift_ref_applicants != null && cell.data.item.id_function_data_main != cell.data.item.id_function_data_applicant">
                                        <template #title>
                                            Geregistreerde functie
                                        </template>
                                        De flexwerker is aangemeld voor de functie: <b>{{ cell.data.item.applicant_function_name ? cell.data.item.applicant_function_name : '-'}}</b>
                                    </b-popover>
                                    <b-popover :target="'popover-target-applicant_function_additional'+cell.data.item.id_shift_data_main" triggers="hover" no-fade placement="bottomright">
                                        <template #title>
                                            {{ trans('sh-additional-functions', 292) }}
                                        </template>
                                        {{ cell.data.item.function_additional_names ? cell.data.item.function_additional_names : trans('sh-no-additional-functions', 292) }}
                                    </b-popover>
                                </template>

                                <template v-slot:fw_buttons="cell">
                                    <div class="d-flex justify-content-end">
                                        <app-button type="primary" v-if="[4].includes(cell.data.item.id_shift_const_status) && cell.data.item.applicant_cancel == 0 && cell.data.item.id_person_data_shift != null" @click="confirm(cell.data.item)">{{trans('confirm', 282)}}</app-button>
                                        <app-button type="secondary-grey" v-if="([4, 15].includes(cell.data.item.id_shift_const_status) && cell.data.item.applicant_cancel == 0)" @click="reject(cell.data.item)">{{trans('reject', 282)}}</app-button>
                                    </div>
                                </template>

                            <template v-slot:person_name="cell">
                                <div v-if="cell.data.item.person_name != null && cell.data.item.person_name != ''" class="d-flex align-items-center justify-content-between">
                                    <span v-overflow-tooltip>{{cell.data.item.person_name}}</span> <b-icon icon="info-circle-fill" :id="'popover-target-'+cell.data.item.id_shift_data_main" class="app-cursor-pointer mr-3" style="min-width: 17px; min-height: 17px;"/>
                                </div>
                                
                                    <b-popover v-if="cell.data.item.person_name != null && cell.data.item.person_name != ''" :target="'popover-target-'+cell.data.item.id_shift_data_main" triggers="hover" no-fade custom-class="custom-popover-header custom-popover-width" placement="bottom">
                                        <template #title>
                                            <b-row>
                                                <b-col cols="12" class="mt-1">
                                                    {{cell.data.item.person_name_alert}}<br />
                                                    <span class="app-local-main-function">{{cell.data.item.person_function_name}}</span><br />
                                                    <span class="app-local-additional-function">{{ cell.data.item.person_function_name_additional }}</span>
                                                </b-col>
                                            </b-row>
                                        </template>
                                        <span class=""><b>Emailadress</b></span>
                                        <b-alert show class="p-2 alert-color">{{cell.data.item.email}}</b-alert>
                                        <span class=""><b>Telefoonnummer</b></span>
                                        <b-alert show class="p-2 alert-color">{{cell.data.item.person_phone_number}}</b-alert>

                                        <span class=""><b>Kwalificaties</b></span>
                                        <b-alert show class="p-2 alert-color">{{cell.data.item.person_qualifications_name ? cell.data.item.person_qualifications_name : '-'}}</b-alert>

                                        <span class=""><b>Geboortedatum</b></span>
                                        <b-alert show class="p-2 alert-color">{{cell.data.item.birth_date}}</b-alert>

                                    </b-popover>
                                </template>

                                <template v-slot:description="cell">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <b-icon icon="info-circle-fill" v-if="cell.data.item.description != null" :id="'popover-target-description'+cell.data.item.id_shift_data_main" class="app-cursor-pointer ml-auto mr-auto" style="min-width: 17px; min-height: 17px;" />
                                    </div>
                                    <b-popover v-if="cell.data.item.description != null" :target="'popover-target-description'+cell.data.item.id_shift_data_main" triggers="hover" no-fade placement="bottomright" custom-class="w-25 text-justify custom-popover-local-notes">
                                        <template #title>
                                            {{trans('sh-description-label',314)}}
                                        </template>
                                        {{ cell.data.item.description }}
                                    </b-popover>
                                </template>

                                <template v-slot:menu-items="cell">
                                    <b-dropdown-item v-if="[1, 3, 11].includes(cell.data.item.id_shift_const_status) && register_person_on_portal == 1" @click="registerFlexworker(cell.data.item)">{{ trans('register-flexworker', 282) }}</b-dropdown-item>
                                    <b-dropdown-item v-if="[1, 3, 4, 11].includes(cell.data.item.id_shift_const_status)" @click="change(cell.data.item)">{{trans('change', 282)}}</b-dropdown-item>
                                    <b-dropdown-item @click="changeDescription(cell.data.item)">{{trans('sh-description-label', 292)}}</b-dropdown-item>
                                    <b-dropdown-item v-if="[1, 3, 4, 11].includes(cell.data.item.id_shift_const_status)" @click="copyShift(cell.data.item.id_shift_data_main)">{{trans('copy', 282)}}</b-dropdown-item>
                                    <b-dropdown-item v-if="[1, 3, 4, 11].includes(cell.data.item.id_shift_const_status)" @click="cancel(cell.data.item)">{{trans('sh-service-withdraw', 294)}}</b-dropdown-item>
                                </template>

                                <!-- <template v-slot:row-details="cell" >
                                <b-row class="app-card-header">
                                    <b-col cols="2">
                                        <p class="col-list-label mb-2">{{trans('kwalifications', 283)}}</p>
                                    </b-col>
                                    <b-col>
                                        <p class="col-form-value mb-2">{{ cell.data.item.qualifications_name ? cell.data.item.qualifications_name : '-' }}</p>
                                    </b-col>
                                </b-row>
                            </template> -->

                                <template v-slot:buttons>

                                    <b-dropdown v-if=" checked_items > 0 && multiMenuVisible" no-caret toggle-class="app-button-multi mr-2" variant="secondary-grey">
                                        <template #button-content>
                                            {{trans('action',282)}} ({{checked_items}})
                                        </template>

                                        <b-dropdown-item v-if="[1, 5].includes(filters.type) && registerVisible" @click="registerFlexworker()">{{ trans('register-flexworker', 282) }}</b-dropdown-item>
                                        <b-dropdown-item v-if="[2,5].includes(filters.type) && confirmVisible" @click="confirmMulti()">{{trans('confirm', 282)}}</b-dropdown-item>
                                        <b-dropdown-item v-if="[2,3,5].includes(filters.type) && rejectVisible" @click="rejectMulti()">{{trans('reject', 282)}}</b-dropdown-item>
                                        <b-dropdown-item v-if="cancelVisible" @click="cancel(null)">{{trans('sh-service-withdraw', 294)}}</b-dropdown-item>
                                    </b-dropdown>

                                    <app-button type="primary" :url="{ name: 'planning-offers-shifts-add-bulk'}">{{trans('planning-add-bulk-service',294)}}</app-button>
                                    <app-button type="secondary-grey" @click="addShift">Dienst aanmaken </app-button>
                                    <app-button v-if="selected_all_rows == '1' || checked_items_export > 0" type="secondary-grey" @click="downloadList">{{ trans('sh-export', 292) }} ({{ selected_all_rows == '1' ? items.total : checked_items_export }})</app-button>
                                </template>

                            </app-view-table>

                            <template #overlay>
                                <div></div>
                            </template>
                        </b-overlay>
                    </b-card>

                </b-col>
            </b-row>

        </div>

        <offers-shifts-change v-if="mShiftChange.show" :show.sync="mShiftChange.show" :args.sync="mShiftChange.args" :result.sync="mShiftChange.result" :return.sync="mShiftChange.return" />
        <offers-shifts-cancel-reason v-if="mShiftCancelReason.show" :show.sync="mShiftCancelReason.show" :args.sync="mShiftCancelReason.args" :result.sync="mShiftCancelReason.result" :return.sync="mShiftCancelReason.return" />
        <shifts-profile-description-change v-if="mShiftDescriptionChange.show" :show.sync="mShiftDescriptionChange.show" :args.sync="mShiftDescriptionChange.args" :result.sync="mShiftDescriptionChange.result" :return.sync="mShiftDescriptionChange.return" />

        <offers-shifts-info v-if="mShiftInfo.show" :show.sync="mShiftInfo.show"
            :args.sync="mShiftInfo.args" :result.sync="mShiftInfo.result" :return.sync="mShiftInfo.return"/>

    </div>

</template>
<script>
 

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import OffersShiftsChange from './OffersShiftsChange.vue';
import UserSettings from '@core/scripts/UserSettings';
import moment from "moment";
import AppDateWeekSelectorSidebar from '../../../../@core/components/AppDateWeekSelectorSidebar.vue';
import OffersShiftsCancelReason from './OffersShiftsCancelReason.vue';
import OffersShiftsQualificationsRelations from '@app/Planning/Offers/Shifts/PersonAdd/Components/OffersShiftsQualificationsRelations.vue';
import ShiftsProfileDescriptionChange from './ShiftsProfileDescriptionChange.vue'
import OffersShiftsInfo from './OffersShiftsInfo.vue';

  export default {

    components: {
        OffersShiftsChange,
        AppDateWeekSelectorSidebar,
        OffersShiftsCancelReason,
        OffersShiftsQualificationsRelations,
        ShiftsProfileDescriptionChange,
        OffersShiftsInfo
    },


    mounted() {
        this.onCreateThis();
        this.show = true;
        if(!this.client_link){
            this.fields[1].filtering = false;
            if(this.ids_location_data_main.length == 0){
                this.fields[2].filtering = false;
            }
        }
    },

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user-portal')).id_user_const_role
    }, 

    computed: {
        cConfirmVisible: {
            get(){
                if(this.items.list.length > 0 && this.items.list.filter((item) => item.selected == '1' && [4].includes(item.id_shift_const_status) && item.cancel == 0 && item.confirm_by_client == 0).length == this.checked_items){
                    return true;
                } else {
                    return false;
                }
            }
        },
        cRejectVisible: {
            get(){
                if(this.items.list.length > 0 && this.items.list.filter((item) => item.selected == '1' && [4, 15].includes(item.id_shift_const_status) && (item.applicant_cancel == 0 ) ).length == this.checked_items){ //|| [5, 6, 9, 11].includes(item.id_shift_const_status) bevestigd
                    return true;
                } else {
                    return false;
                }
            }
        }
    },

    data() {
        return {
            userRole: null,
            refreshTable:0,
            filters: {      
                ids_shift_data_main: null,       
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                date_type: 1,
                location: '',
                ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main).map(item => item.value),
                ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main).map(item => item.value),
                ids_group_const_type: [],
                client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,
                offer_date: null,
                offer_week: null,
                type: 1,
                filtering: {                       
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: {}, widthClass:'app-width-min-350', refreshList: false},
                    client_departments: { type: 'list', changed: 0, values: [], function: "getDepartmentsShifts", parameters: {ids_client_data_main: [], ids_location_data_main: []}, widthClass:'app-width-min-350', refreshList: false},
                    functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},                   
                    shift_statuses: { type: 'list', changed: 0, values: [], function: "getShiftStatuses", parameters: null, widthClass:'app-width-min-350'},
                    persons: {type: 'list', changed: 0, values: [], function: 'getFlexworkers', parameters: {id_settings_const_type: 6, list_type: 'shifts_list'}, widthClass:'app-width-min-250'},
                    daytime_name: { type: 'list', changed: 0, values: [], function: "getShiftDaytime", parameters: null, widthClass:'app-width-min-350'},
                    current_persons: {type: 'list', changed: 0, values: [], function: 'getCurrentFlexworkers', parameters: {id_settings_const_type: 12, list_type: 'shifts_list'}, widthClass:'app-width-min-250', refreshList: false},

                },
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                }
                
            },

            sidebar_filters: {               
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                date_type: 1,
                location: '',
                offer_date: null,
                offer_week: null,
                type: 1,
                
            },

            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", thStyle: { "min-width": "30px", width: "30px" },visible: true  },
                { key: "location_name", label: this.trans('location',280), thStyle: { "min-width": "120px", "width": "150px" }, visible: true, filtering: true,  filtering_name: "client_locations"},
                { key: "department", label: this.trans('department',280), thStyle: { "min-width": "120px", "width": "100%" }, visible: true, filtering: true, filtering_name: "client_departments"},
                { key: "qualifications", label: 'KW', thStyle: { "min-width": "50px", "width": "50px" }, visible: true},
                { key: "shift_function_name", label: this.trans('sh-function',292), thStyle: { "min-width": "120px", "width": "200px" }, visible: true, filtering: true, filtering_name: "functions"},
                { key: "date", label: this.trans('pl-date',288), thStyle: { "min-width": "120px", "width": "100px" }, visible: true, filtering: true, filtering_name: "dates"},
                { key: "week_number", label: this.trans('sh-week',292), thStyle: { "min-width": "60px", "width": "60px" }, visible: true},
                { key: "hour_from", label: this.trans('sh-begin',292), thStyle: { "min-width": "70px", "width": "70px" }, visible: true, filtering: false},
                { key: "hour_to", label: this.trans('sh-end',292), thStyle: { "min-width": "70px", "width": "70px" }, visible: true, filtering: false},
                { key: "daytime", label: this.trans('sh-type-of-service', 292), thStyle: { "min-width": "80px", "width": "130px" }, visible: true, filtering: true, filtering_name: 'daytime_name'},
                { key: "person_name", label: this.trans('flexworker',280), thStyle: { "min-width": "180px", "width": "270px"}, visible: true, filtering: true, filtering_name: 'current_persons'}, 
                { key: "description", label: this.trans('td-description-short',314), thStyle: { "min-width": "50px", "width": "150px"}, tdClass: "app-local-row-center", visible: true, filtering: false, sortable: false},
                { key: "contract_type", label: this.trans('sh-contract',292), thStyle: { "min-width": "100px", "width": "100px"}, visible: true, filtering: false, sortable: true },             
                { key: "status_name", label: this.trans('status',283), thStyle: { "min-width": "100px", "width": "110px" }, visible: true},
                { key: "shift_code", label: this.trans('sh-shift-code',292), thStyle: { "min-width": "100px", "width": "120px" }, visible: true},
                { key: "fw_buttons", label: '', thStyle: { "min-width": "330px", "max-width": "330px" }, visible: false},
            ],

            list_types: [
                { value: 1, text: this.trans('open',282) },
                { value: 2, text: this.trans('confirm',282) },
                { value: 3, text: this.trans('planned',282) },
                { value: 4, text: this.trans('ready',282) },
                { value: 5, text: this.trans('settings-all',167)},
            ],

            custom_columns: ['selected', 'fw_buttons', 'person_name', 'shift_function_name', 'qualifications', 'description'],
            custom_headers: ['selected'],
            selected_all_rows: '0',
            checked_items: 0,
            checked_items_export: 0,

            removeAll: false,

            items: [],
            locations:[],
            loading_locations: false,

            mShiftChange:{
                show: false,
                args: {
                    id_shift_data_main: null,
                    date_from: null,
                    hour_from: null,
                    hour_to: null,
                    id_shift_const_daytime: null,
                    ids_function_data_main: null,
                    function_additional: null,
                    id_cao_data_main: null,
                    id_office_data_location: null,
                    location: null,
                    department: null,
                    filters: null,
                    type: 'portal-list',
                    description: null,
                    id_hour_data_main: null,
                    shift_code: null
                },
                result: 0, return: null
            },

            mShiftDescriptionChange:{
                show: false,
                args: {
                    id_shift_data_main: null,
                    type: 'portal-list',
                    description: null
                },
                result: 0, return: null
            },

            mFilters: { show: false, 
                            args: {
                                    filters : null,
                                    locations: []
                                  }, 
                            result: 0,
                        return: null
            },
            
            register_person_on_portal: JSON.parse(localStorage.getItem('user-portal')).register_person_on_portal,
            client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,
            ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main).map(item => item.value),
            ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main).map(item => item.value),
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            loading: false,
            refresh: 0,
            sidebar_open: false,
            multiMenuVisible: true,
            confirmVisible: true,
            rejectVisible: true,
            cancelVisible: true,
            registerVisible: true,
            mShiftCancelReason: { show: false, args: {
                                                        ids_shift_data_main: null, 
            }, result: 0, return: null},

            mShiftInfo: {
                show: false,
                args: {
                    id_person_data_main: null,
                    id_shift_data_main: null
                }, result: 0, return: {}
            }
        };
    },

    methods: {

        getList() {

           this.loading = true;
           axios
                .post("shifts/getList", {
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {      
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.filters.ids_shift_data_main = null;
                });
        },  

        openFilters(){
              
            // this.mFilters.args.filters = JSON.parse(JSON.stringify(this.filters));
            // this.mFilters.args.locations = JSON.parse(JSON.stringify(this.locations)); ;
            // this.mFilters.show = true;
            this.updateSidebarFilters(); 
            this.sidebar_open = true;

        },

        cancel(item = null){

            this.alertClass.openAlertConfirmation(((item == null && this.checked_items > 1) ? this.trans('pl-services-cancel', 291) : this.trans('pl-service-cancel', 291))).then(res =>{ 
                
                if(res == true){

                    let ids_shift_data_main = [];
                    if(item == null){//multi
                        ids_shift_data_main = this.items.list.filter((item) => {return item.selected == '1' && ([1, 3, 11].includes(item.id_shift_const_status) )}).map(item => item.id_shift_data_main);
                    }else{//single
                        ids_shift_data_main = [item.id_shift_data_main];
                    }

                    this.mShiftCancelReason.args.ids_shift_data_main = ids_shift_data_main;
                    this.mShiftCancelReason.result = 0;
                    this.mShiftCancelReason.show = true;

                }

            });

        },

        change(item){
            this.mShiftChange.args.id_shift_data_main = item.id_shift_data_main;
            this.mShiftChange.args.date_from = item.date;
            this.mShiftChange.args.hour_from = item.hour_from;
            this.mShiftChange.args.hour_to = item.hour_to;
            this.mShiftChange.args.id_shift_const_daytime = item.id_shift_const_daytime;
            this.mShiftChange.args.ids_function_data_main = [item.id_function_data_main].concat(JSON.parse(item.function_additional));
            this.mShiftChange.args.id_cao_data_main = item.id_cao_data_main;
            this.mShiftChange.args.location = {name: item.location_name, value: item.id_location_data_main};
            this.mShiftChange.args.id_location_data_main = item.id_location_data_main;
            this.mShiftChange.args.department = {name: item.department, value: item.id_department_data_main, id_cao_data_main: item.id_cao_data_main, additional_functions: item.client_additional_functions, sleep_shift_parameters: item.sleep_shift_parameters };
            this.mShiftChange.args.id_office_data_location = item.id_office_data_location;
            this.mShiftChange.args.id_hour_data_main = item.id_hour_data_main;
            this.mShiftChange.args.shift_code = item.shift_code;
            this.mShiftChange.args.filters = JSON.stringify(this.filters);
            this.mShiftChange.args.ids_qualification_const_type = item.ids_qualification_const_type ? JSON.parse(item.ids_qualification_const_type) : [];
            this.mShiftChange.args.description = item.description;
            this.mShiftChange.args.ids_group_const_type = item.ids_group_const_type ? JSON.parse(item.ids_group_const_type) : [];

            this.mShiftChange.show = true;
        },

        changeDescription(item){
            this.mShiftDescriptionChange.args.id_shift_data_main = item.id_shift_data_main;
            this.mShiftDescriptionChange.args.description = item.description;
            this.mShiftDescriptionChange.args.filters = JSON.stringify(this.filters);

            this.mShiftDescriptionChange.show = true;
        },

        copyShift(id_shift_data_main){

            this.$router.push({ name: "planning-offers-shifts-add-copy", params: { id_shift_data_main: id_shift_data_main}});
        },

        registerFlexworker(item = null){

            let ids_shift_data_main = [];

            if(item == null){
                let selected_all = this.items.list.filter(function(value) { 
                        return value.selected == true; 
                    }).length;

                 if(this.checked_items != selected_all){
                      this.alertClass.openAlertConfirmation(this.trans('sh-add-people-to-shifts-confirmation',221,{checked_items : this.checked_items})).then(res => {
                        if(res){
                            ids_shift_data_main = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1].includes(value.id_shift_const_status) }).map(a => a.id_shift_data_main);

                            let dates = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1].includes(value.id_shift_const_status)  }).map(a => a.date);

                            var min_date = dates.reduce(function (a, b) { return moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY') ? a : b; });
                    

                            this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main, start_date: min_date, returnType: "offers_list" }});
                        }
                      });
                 }
                else{
                    ids_shift_data_main = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1].includes(value.id_shift_const_status)  }).map(a => a.id_shift_data_main);

                    let dates = this.items.list.filter(function(value) {
                                                    return value.selected == true && [1].includes(value.id_shift_const_status) }).map(a => a.date);
                                                    
                    var min_date = dates.reduce(function (a, b) { return moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY') ? a : b; });
                    

                    this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main, start_date: min_date, returnType: "offers_list" }});
                }
            }else {
                ids_shift_data_main = [item.id_shift_data_main];
                this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main, start_date: item.date, returnType: "offers_list" }});
            }
        },

        confirm(item){

                this.alertClass.openAlertConfirmation(this.trans('pl-employee-confirmation', 291, {person_name : item.person_name_alert})).then(res => {
              
                    if(res == true){

                        this.loading = true;
           
                        axios
                            .post("shifts/person/confirm", {
                                id_shift_ref_applicants: item.id_shift_ref_applicants,
                                id_person_data_main: item.id_person_data_main,
                                id_shift_data_main: item.id_shift_data_main,
                                return_type: 'shifts_list',
                                filters: JSON.stringify(this.filters)
                            })
                            .then((res) => {                 
                                this.filters.page = res.data.page;
                                this.items = res.data.items;
                            
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.checked_items = 0;
                                this.checked_items_export = 0;
                            });

                    }

             });

        },

        reject(item){

            
             this.alertClass.openAlertConfirmation(this.trans('pl-reject-employee-confirmation', 291, {person_name : item.person_name_alert})).then(res =>{ 
                 if(res == true){
                     this.loading = true;

                     axios
                         .post("shifts/person/reject", {
                             id_shift_ref_applicants: item.id_shift_ref_applicants,
                             id_shift_data_main: item.id_shift_data_main,
                             filters: JSON.stringify(this.filters),
                             return_type: 'shifts_list',
                         })
                         .then((res) => {                 
                             this.filters.page = res.data.page;
                             this.items = res.data.items;
                         })
                         .catch((error) => {
                             console.error(`error during request: ${error}`);
                         })
                         .finally(() => {
                             this.loading = false;
                             this.checked_items = 0;
                             this.checked_items_export = 0;
                         });
                        
                 }

             });
        },

        confirmMulti(){
            this.alertClass.openAlertConfirmation(this.trans('pl-employees-confirm', 291)).then(res =>{ 
                 if(res == true){

                    var shifts = this.items.list.filter((item) => {return item.selected == "1" && [4].includes(item.id_shift_const_status) && item.applicant_cancel == 0}).map((item) => {return {id_shift_data_main: item.id_shift_data_main, id_shift_ref_applicants: item.id_shift_ref_applicants, id_person_data_main: item.id_person_data_main}});

                    this.loading = true;

                    axios
                            .post("shifts/person/confirmMulti", {
                                shifts: JSON.stringify(shifts),
                                return_type: 'shifts_list',
                                filters: JSON.stringify(this.filters)
                            })
                            .then((res) => {                 
                                this.filters.page = res.data.page;
                                this.items = res.data.items;
                            
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.checked_items = 0;
                                this.checked_items_export = 0;
                            });

                

                 }
            });
        },

        rejectMulti(){
            this.alertClass.openAlertConfirmation(this.trans('pl-employees-reject', 291)).then(res =>{ 
                 if(res == true){

                    var shifts = this.items.list.filter((item) => {return item.selected == "1" && (([4, 15].includes(item.id_shift_const_status) && item.applicant_cancel == 0 ) || [5, 6, 9, 11].includes(item.id_shift_const_status))}).map((item) => {return {id_shift_data_main: item.id_shift_data_main, id_shift_ref_applicants: item.id_shift_ref_applicants}})
                    this.loading = true;

                    axios
                            .post("shifts/person/rejectMulti", {
                                shifts: JSON.stringify(shifts),
                                return_type: 'shifts_list',
                                filters: JSON.stringify(this.filters)
                            })
                            .then((res) => {                 
                                this.filters.page = res.data.page;
                                this.items = res.data.items;
                            
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.checked_items = 0;
                            });
                 }
            });
        },

        select(value){

                var selected_items = 0;
                selected_items = this.items.list.filter((item) => {return item.selected == '1' && (
                                                                                                    [1, 3, 11].includes(item.id_shift_const_status) ||
                                                                                                    [1, 3].includes(item.id_shift_const_status) || 
                                                                                                    ([4, 15].includes(item.id_shift_const_status) && item.applicant_cancel == 0) || 
                                                                                                    item.id_shift_const_status == 10 
                                                                                                    
                                                                                                    )}).length;
                
                this.checked_items = selected_items

                this.checked_items_export = this.items.list.filter((item) => {return item.selected == '1'}).length;


            this.isMultiMenuVisible();
        },

        selectAll(value){
            if(value == "1"){
                this.items.list.forEach(item => {
                    item.selected = "1";
                });

                this.checked_items = this.items.list.length;
                this.checked_items_export = this.items.list.length;
            } else {
                this.items.list.forEach(item => {
                    item.selected = "0";
                });

                this.checked_items = 0;
                this.checked_items_export = 0;
            }

            this.isMultiMenuVisible();
        },

        isMultiMenuVisible(){

            var confirmLength = this.items.list.filter((item) => item.selected == '1' && [4].includes(item.id_shift_const_status) && item.applicant_cancel == 0).length;
            if(confirmLength == this.checked_items){
                    this.confirmVisible = true;
                } else {
                    this.confirmVisible = false;
                }
            
            var rejectLength = this.items.list.filter( (item) => item.selected == '1' && ( ( [4, 15].includes(item.id_shift_const_status) && item.applicant_cancel == 0 )) ).length; //|| [5, 6, 9, 11].includes(item.id_shift_const_status) bevestigd
            if(rejectLength == this.checked_items){
                    this.rejectVisible = true;
            } else {
                    this.rejectVisible = false;
            }

            var cancelLength = this.items.list.filter((item) => item.selected == '1' && ([1, 3, 4, 11].includes(item.id_shift_const_status) )).length
            if(cancelLength == this.checked_items){
                this.cancelVisible = true;
            } else {
                this.cancelVisible = false;
            }

            var registerLength = this.items.list.filter((item) => item.selected == '1' && [1, 3, 11].includes(item.id_shift_const_status)).length
            if(registerLength == this.checked_items){
                this.registerVisible = true;
            } else {
                this.registerVisible = false;
            }

            if(this.confirmVisible || this.rejectVisible || this.cancelVisible || (this.registerVisible && this.register_person_on_portal == 1)){
                this.multiMenuVisible = true;
            } else {
                this.multiMenuVisible = false;
            }

        },

        perPageChanged() {
            this.filters.changed++;
        },

        getDayOfWeekName(item){
            var date = moment(item.date, 'DD-MM-YYYY');

            var name = date.locale('Nl').format('dddd');

            return name + ', wk '+ date.week();
        },

        setSidebarFilters(){
            this.filters = JSON.parse(JSON.stringify(this.sidebar_filters));

            this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop');
            this.sidebar_open = false;
        },

        updateSidebarFilters(){
            this.sidebar_filters = JSON.parse(JSON.stringify(this.filters));

            if(this.filters.offer_date == null) this.sidebar_filters.offer_date = null;
            if(this.filters.offer_week == null) this.sidebar_filters.offer_week = null;
        },

        setLocation(){
            if(!this.client_link){
                if(this.ids_location_data_main.length > 0){
                    this.filters.filtering.client_locations.values = [];
                    this.filters.filtering.client_departments.parameters.ids_location_data_main = this.ids_location_data_main.map(item => item.value);
                }
            } else {
                this.filters.ids_location_data_main = [];
            }
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 12);
        },

        async loadSettings(load = true) {

            if(load){
                var sFilters = await this.userSettings.checkSettings(12, this.filters, 'portalShiftList');

                
                this.filters.ids_location_data_main = this.ids_location_data_main;
                this.filters.ids_department_data_main = this.ids_department_data_main;
                this.filters.client_link = this.client_link;
                this.setLocation();

                this.filters.type = sFilters.type;
                this.filters.date_type = sFilters.date_type;
                this.filters.offer_date = sFilters.offer_date == null ?  moment(new Date()).format('DD-MM-YYYY') : sFilters.offer_date;
                this.filters.offer_week = sFilters.offer_week == null ? moment(new Date(), 'DD-MM-YYYY').format('YYYY-W') : sFilters.offer_week;
                this.filters.sorting = sFilters.sorting;
                this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;  

                this.sidebar_filters = JSON.parse(JSON.stringify(this.filters));

                if(this.$route.params.from_dashboard && this.$route.params.from_dashboard == 1){
                    this.filters.type = 3;
                    this.filters.date_type = 1;
                    this.filters.offer_date = null;
                }else if(this.$route.params.from_dashboard && this.$route.params.from_dashboard == 2){
                    this.filters.type = 6;
                    this.filters.date_type = 1;
                    this.filters.offer_date = null;
                }
            }

            if([2, 3, 5].includes(this.filters.type)){
                this.fields[15].visible = true;
            } else {
               this.fields[15].visible = false; 
            }

            if(this.filters.type == 6){
                this.fields[13].visible = true;
            } else {
                this.fields[13].visible = false;
            }

            if([1].includes(this.filters.type)){
                this.fields[10].visible = false;
                this.fields[12].visible = false;
            } else {
              this.fields[10].visible = true;
              this.fields[12].visible = true;
            }
            this.refreshTable++;
            await this.$nextTick();
            this.saveSettings();
            
        },

        async onCreateThis() { 
            
            let url_params = new URLSearchParams(window.location.search);
            let url = new URL(window.location.href);

            if(url_params.has('type')){
                this.filters.type = parseInt(url_params.get('type'));
                this.filters.offer_date = null;
                this.filters.offer_week = null;

                this.sidebar_filters.type = parseInt(url_params.get('type'));
                this.sidebar_filters.offer_date = null;
                this.sidebar_filters.offer_week = null;
                this.loadSettings(false);

            }else if (url.searchParams.has('id')) {
                this.filters.type = 3;
                this.filters.offer_date = null;
                this.filters.offer_week = null;

                this.sidebar_filters.type = 3;
                this.sidebar_filters.offer_date = null;
                this.sidebar_filters.offer_week = null;
                this.filters.ids_shift_data_main = url.searchParams.get('id')
                this.loadSettings(false);

            }else{
                await this.loadSettings();
            }

            this.getList();
        
            this.$watch('filters.offer_date', function(newVal, oldVal) {
                if (!this.loading && this.filters.date_type == 1) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';                     
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                } 
            });

            this.$watch('filters.type',function(newVal, oldVal) {
                if (!this.loading) {
                    this.filters.filtering.current_persons.values = [];
                    this.items = [];
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();

                    if([2, 3, 5].includes(this.filters.type)){
                        this.fields[15].visible = true;
                    } else {
                    this.fields[15].visible = false; 
                    }

                    if(this.filters.type == 6){
                        this.fields[13].visible = true;
                    } else {
                        this.fields[13].visible = false;
                    }

                    if([1].includes(this.filters.type)){
                        this.fields[10].visible = false;
                        this.fields[12].visible = false;
                    } else {
                    this.fields[10].visible = true;
                    this.fields[12].visible = true;
                    }
                    this.refreshTable++;
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });

            this.$watch('filters.location',function(newVal, oldVal) {
                if (!this.loading) {
                    if(newVal != null) {
                        this.fields[1].filtering = false;
                        this.filters.filtering.client_departments.parameters.ids_location_data_main = [newVal.value];
                    } else {
                      this.fields[1].filtering = true; 
                      this.filters.filtering.client_departments.parameters.ids_location_data_main = []; 
                    }
                    this.filters.filtering.client_departments.refreshList = true; 
                    this.filters.filtering.current_persons.refreshList = true;
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                } 
            });

            this.$watch('filters.offer_week', function(newVal, oldVal) {
                if (!this.loading && this.filters.date_type == 2) { 
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';      
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });

        
            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                    this.filters.filtering.client_departments.refreshList = true; 
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });

            this.$watch('filters.filtering.client_locations.values', function(newVal, oldVal) {
                if(this.filters.filtering.client_locations.values.length == 0){
                    this.filters.filtering.client_departments.parameters.ids_location_data_main = [];
                    this.filters.filtering.client_departments.refreshList = true; 
                    this.filters.filtering.current_persons.refreshList = true;
                }
            });

            this.$watch('filters.filtering.client_locations.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.filters.filtering.client_departments.parameters.ids_location_data_main = this.filters.filtering.client_locations.values;
                    this.filters.filtering.client_departments.refreshList = true; 
                    this.filters.filtering.current_persons.refreshList = true;
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                } 
            });

            this.$watch('filters.filtering.client_departments.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });

            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });

            this.$watch('filters.filtering.daytime_name.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });   

            this.$watch('filters.filtering.current_persons.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                } 
            });

            this.$watch('filters.filtering.shift_statuses.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });
          

            this.$watch('filters.date_type', function(newVal, oldVal) {
                if (!this.loading) { 
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    if(this.filters.date_type == 1){
                        this.filters.offer_date = moment().format('DD-MM-YYYY');
                    } else if(this.filters.date_type == 2){
                        this.filters.offer_week = moment().format('YYYY-WW');
                    }
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                    this.filters.filtering.current_persons.refreshList = true;
                } 
            });

            
            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                } 
            });     
           
            this.$watch('filters.page', function(newVal, oldVal) {
                this.cheked_items = 0;
                if (!this.loading) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                }   
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading) {
                    this.filters.page = 1;
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.updateSidebarFilters();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading) { 
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                    this.updateSidebarFilters();
                } 
            });

            this.$watch('mShiftChange.result', function(newVal, oldVal) {
                if(newVal == 1){
                    this.items = this.mShiftChange.return;
                    this.mShiftChange.result = 0;
                    this.getList();
                }
            });

            this.$watch('mShiftDescriptionChange.result', function(newVal, oldVal) {
                if(newVal == 1){
                    this.items = this.mShiftDescriptionChange.return;
                    this.mShiftDescriptionChange.result = 0;
                    this.getList();
                }
            });

            this.$watch('mShiftCancelReason.result', function(newVal, oldVal) {
                    if(this.mShiftCancelReason.result == 1){
                        this.checked_items = 0;
                        this.checked_items_export = 0;
                        this.selected_all_rows = '0';
                        this.getList();
                    }
            });

        },

        addShift(){
            this.$router.push({ name: "planning-offers-shifts-add", params: { } });
        },

        openShiftInfo(item) {
            if (item.id_person_data_main == null) return;
            this.mShiftInfo.args.id_person_data_main = item.id_person_data_main;
            this.mShiftInfo.args.id_shift_data_main = item.id_shift_data_main;
            this.mShiftInfo.show = true;
        },

        downloadList(){
            this.loading = true;

            var selected_items = [];

            if (this.checked_items_export != 0 && this.selected_all_rows != '1') {
                selected_items = this.items.list.filter(item => item.selected == true).map(item => item.id_shift_data_main);
            }

            axios
                .post("shifts/downloadList", {
                    filters: JSON.stringify(this.filters),
                    selected_items: JSON.stringify(selected_items),
                    selected_all_rows: this.selected_all_rows,
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {                 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Diensten ' + moment().format('DD-MM-YYYY - HHmmss') +'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        
    },
  };
</script>
<style>

.app-local-main-function{
    font-weight: 600;
    font-size: 13px;
}

.app-local-additional-function{
    font-weight: 300;
    font-size: 12px;
}

.app-local-sidebar-width .b-sidebar{
    width: 338px !important;
}

.app-local-fw-buttons{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.custom-popover-header .popover-header{
    background-color: #f5faff !important;
}

.custom-popover-width{
    min-width: 10px !important;
    max-width: 25% !important;
    max-height: 75%;
    overflow-y: auto;
}

.custom-popover-local-notes {
    overflow-y: auto !important;
    max-height: 500px !important;
}

</style>
<style scoped>
.app-local-header-height{
    height: 32px;
}

.app-local-margin{
    position: absolute;
    top: 90vh;
    width: 100%;

}

.alert-color{
    color: #174066 !important;
}

.custom-avatar{
    height: 46px;
    width: 46px;
}

@media (min-width: 1405px) {
    .app-local-big{
        display:block !important;
    }
    .app-local-smart{
        display:none !important;
    }
}

@media (max-width: 1405px) {
    .app-local-big{
        display:none !important;
    }
    .app-local-smart{
        display:block !important;
    }
}
</style>

