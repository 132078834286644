<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('sh-change-services',292)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-row-left-label :label="trans('n-notes',204)">
                    <app-memo v-model="cArgs.description" rows="5" :maxLength="10000"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',282)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',282)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import ShiftsTypeClass from "./ShiftsTypeClass.js";
import { datecheck } from "@validations";
import moment from "moment";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cDayTime:{
            get(){return this.day_time},
            set(value){this.$emit('update:day_time', value)}
        }
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        change() {            
          
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }
                this.loading = true;
                axios
                    .post("shifts/changeDescription", {
                        id_shift_data_main: this.cArgs.id_shift_data_main,
                        description: this.cArgs.description,
                        filters: this.cArgs.filters,
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        
                        this.cReturn = res.data.items;   
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading = false;
                    });
                })
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped>

</style>
