<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('pl-change-services',288)">
        <ValidationObserver ref="form" >
            <b-form>
                
                <app-row-left-label :label="trans('pl-date',288)">
                        <app-date id="vi-date-from" v-model="cArgs.date_from" validatorRules="required|date-check" :validatorName="trans('pl-date',288)" :validatorCustomMessage="{ 'disable-date-past': 'Datum mag niet meer dan 7 dgn in het verleden liggen' }" :disabledDatesPass="8" :disabled="args.id_hour_data_main != null"/>
                </app-row-left-label>

                 <app-row-left-label :label="trans('pl-start-time',288)" >
                       <app-time id="vi-time-from" v-model="cArgs.hour_from" validatorRules="required" :validatorName="trans('pl-start-time',288)" :disabled="args.id_hour_data_main != null"/>
                </app-row-left-label>

                 <app-row-left-label :label="trans('pl-end-time',288)">
                        <app-time id="vi-time-to" v-model="cArgs.hour_to" validatorRules="required" :validatorName="trans('pl-end-time',288)" :disabled="args.id_hour_data_main != null"/>
                </app-row-left-label>

                 <app-row-left-label :label="trans('sh-type-of-service',292)" >
                     <app-radio-group  v-model="cArgs.id_shift_const_daytime" :validatorName="trans('sh-type-of-service',292)" validatorRules="required" :options="cDayTime" :disabled="args.id_hour_data_main != null" :key="refresh_days"/>
                </app-row-left-label>

                <app-row-left-label>
                    <app-multi-select v-model="cArgs.ids_qualification_const_type" type="getQualifications"
                        :label="trans('kwalifications', 283)" :isAllOption="false" />
                </app-row-left-label>

                <app-row-left-label>
                    <app-multi-select v-model="cArgs.ids_group_const_type" :args.sync="targetgroup_args" type="getTargetgroup"
                        :label="trans('target-group', 292)" :isAllOption="false" />
                </app-row-left-label>

                <app-divider-modal class="mt-2 mb-3" :isLine="true" v-if="this.cArgs.id_cao_data_main != null"/>

                <app-row-left-label :label="trans('location',280)">
                        <app-select v-model="cArgs.location" type="getClientLocations" validatorRules="required" :validatorName="trans('location',280)"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('department',280)">
                    <app-select v-model="cArgs.department" type="getDepartments" :refreshIndex="refresh_department" :args.sync="cArgs" validatorRules="required" :validatorName="trans('department',280)"/>
                </app-row-left-label>

                <app-row-left-label v-if="this.cArgs.id_cao_data_main != null ">
                        <app-multi-select v-model="cArgs.ids_function_data_main" :label="trans('sh-function',292)" :additionalLabel="trans('sh-alternative-functions',292)" labelPosition="left" :addButtonLabel="trans('sh-add-function',292)" type="getDepartmentsFunctions" validatorRules="required" :validatorName="trans('sh-function',292)" :validatorCustomMessage="{'required': trans('sh-select-minimum-one-function',293)}" :args.sync="cArgs.department" :isAllOption="false" :refreshIndex.sync="refresh_functions" :key="refresh_multi" :disabled="cArgs.department == null"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('sh-shift-code',292)">
                    <app-input v-model="cArgs.shift_code" validatorName="Shiftcode" mask="lettersE6-50"/>
                </app-row-left-label>

                <!-- <app-row-left-label label="Vestiging">
                    <app-select v-model="cArgs.location" validatorRules="required" validatorName="Vestiging" type="getLocations" />
                </app-row-left-label> -->

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">Annuleren</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                Opslaan
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import ShiftsTypeClass from "./ShiftsTypeClass.js";
import { datecheck } from "@validations";
import moment from "moment";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cDayTime:{
            get(){return this.day_time},
            set(value){this.$emit('update:day_time', value)}
        }
    },

    watch: {

        "cArgs.hour_from": {
            handler: function() {
                this.cArgs.id_shift_const_daytime = this.shift_type_class.calculate(this.cArgs.hour_from, this.cArgs.hour_to)
            }
        },

        "cArgs.hour_to": {
            handler: function() {
               this.cArgs.id_shift_const_daytime = this.shift_type_class.calculate(this.cArgs.hour_from, this.cArgs.hour_to)
            }
        },

        "cArgs.location": {
            handler: function(val){
                if (this.cArgs.location !== null) {
                    this.cArgs.id_location_data_main = this.cArgs.location.value;
                    this.cArgs.department = null;
                    this.refresh_department++;
                    this.refresh++;

                    if (this.cArgs.ids_function_data_main.length > 0){
                        this.cArgs.ids_function_data_main = [];
                        this.functions_additional = [];
                        this.refresh_multi++;
                    }
                }
            },
            deep: true
        },

        "cArgs.department": {
            handler: function(newVal, oldVal){
                if (this.cArgs.department !== null  && !this.loading) {
                    this.cArgs.ids_function_data_main = [];
                    this.refresh++;

                    if (this.cArgs.ids_function_data_main.length > 0){
                        this.cArgs.ids_function_data_main = [];
                        this.functions_additional = [];
                    }
                    this.refresh_multi++;

                }

                this.checkSleepShiftParameters();
            },
            deep: true
        },

        "cArgs.ids_function_data_main": {
            handler: function(val){
                if(this.id != this.cArgs.ids_function_data_main[0]){
                    this.addAdditionals();
                        this.id = this.cArgs.ids_function_data_main[0];
                }
            }
        }
    },

    data() {
        return {
            loading: false,
            shift_type_class :new ShiftsTypeClass(),
            day_time: [
                { value: 1, text: this.trans('day-part-morning',280) },
                { value: 2, text: this.trans('day-part-afternoon',280) },
                { value: 3, text: "Dag" },
                { value: 4, text: "Avond" },
                { value: 5, text: "Nacht" },
                { value: 6, text: "Slaapdienst" }
            ],
            refresh_department: 0,
            refresh_functions: 0,
            refresh: 0,
            refresh_multi: 0,
            id: -1,
            refresh_days:0,
            functions_additional: [],  
            targetgroup_args: {ids_department_data_main: [this.args.department.value]},
        };
    },

    mounted(){
        this.id = this.args.ids_function_data_main.length > 0 ? this.args.ids_function_data_main[0] : -1;
        this.checkSleepShiftParameters();
    },

    methods: {
        change() {            
          
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }
                this.loading = true;

                axios
                    .post("shifts/change", {
                        id_shift_data_main: this.cArgs.id_shift_data_main,
                        date_from: this.cArgs.date_from,
                        hour_from: this.cArgs.hour_from,
                        hour_to: this.cArgs.hour_to,
                        ids_function_data_main: this.cArgs.ids_function_data_main,
                        id_client_data_location: this.cArgs.location.id_client_data_location,
                        id_location_data_main: this.cArgs.location.value,
                        id_department_data_main: this.cArgs.department.value,
                        id_cao_data_main: this.cArgs.id_cao_data_main,
                        id_office_data_location: this.cArgs.id_office_data_location,
                        type: this.cArgs.type,
                        filters: this.cArgs.filters,
                        id_shift_const_daytime: this.cArgs.id_shift_const_daytime,
                        shift_code: this.cArgs.shift_code,
                        ids_qualification_const_type: this.cArgs.ids_qualification_const_type,
                        ids_group_const_type: this.cArgs.ids_group_const_type
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);

                        this.cReturn = res.data.items;                     
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    }).finally(() => {
                        this.loading = false;
                    });
                })
        },

        checkSleepShiftParameters(){

            if(this.cArgs.department != null && this.cArgs.department.sleep_shift_parameters != null){

                let sleep_shift_param = JSON.parse(this.cArgs.department.sleep_shift_parameters);
                if(sleep_shift_param.sleep_shift == 1){//jesli się nalezy sleep shift
                    this.cDayTime[5].disabled = false;
                }else{
                    this.cDayTime[5].disabled = true;
                    this.cArgs.id_shift_const_daytime = this.shift_type_class.calculate(this.cArgs.hour_from, this.cArgs.hour_to)
                }
                this.refresh_days++;
            }

        },

        addAdditionals(){
            if(this.cArgs.department != null){
                var additional_functions_array = JSON.parse(this.cArgs.department.additional_functions)
                this.functions_additional = additional_functions_array;

                var functions = [];
                
                if(this.cArgs.ids_function_data_main[0] != null){
                    this.cArgs.ids_function_data_main = [this.cArgs.ids_function_data_main[0]]
                }
                if(this.functions_additional == null){
                    this.functions_additional = [];
                    return
                }

                for(var i=0; i<this.functions_additional.length; i++){
                    if(this.cArgs.ids_function_data_main.includes(this.functions_additional[i].id_function_data_main)){
                        functions = this.cArgs.ids_function_data_main.concat(this.functions_additional[i].ids_function_data_additional);
                        this.cArgs.ids_function_data_main = functions;
                        functions = [];
                        break;
                    }else if(this.cArgs.ids_function_data_main[0] != null){
                        this.cArgs.ids_function_data_main = [this.cArgs.ids_function_data_main[0]]
                    }
                }
                this.refresh_multi++;
            }
        },


        closeModal() {
            this.$emit("update:show", false);
        },




    },
};
</script>

<style scoped>

</style>
