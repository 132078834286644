<template>
    <b-card>
        <b-overlay :show="loading" :opacity="0.6">
            <b-row class="app-card-header app-local-header-height">
                <b-col start>
                    <div class="app-card-tittle app-local-title-height d-flex align-items-center">
                        <div>{{ trans('sh-old-shifts', 292) }} <span v-if="!loading">({{ items.total }})</span>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <app-view-table :isTopBar="false" :filters="filters" :items="items" :fields="fields"
                :customColumns="custom_columns" :customColumnsHeaders="custom_headers" :isDropdownMenu="false">

                <template v-slot:qualifications="cell">
                    <offers-shifts-qualifications-relations
                        :dotColor="cell.data.item.ids_qualification_const_type && JSON.parse(cell.data.item.ids_qualification_const_type).length != 0 ? cell.data.item.person_qualification_compatibility : 2"
                        :idPersonDataMain="cell.data.item.id_person_data_main"
                        :idsShiftDataMain="[cell.data.item.id_shift_data_main]"
                        :ids_qualification_const_type="JSON.parse(cell.data.item.ids_qualification_const_type)" />

                </template>

                <template v-slot:description="cell">
                    <div class="d-flex align-items-center justify-content-between">
                        <b-icon icon="info-circle-fill" v-if="cell.data.item.description != null"
                            :id="'popover-target-shift-info-description' + cell.data.item.id_shift_data_main"
                            class="app-cursor-pointer ml-auto mr-auto" style="min-width: 17px; min-height: 17px;" />
                    </div>
                    <b-popover v-if="cell.data.item.description != null"
                        :target="'popover-target-shift-info-description' + cell.data.item.id_shift_data_main" triggers="hover"
                        no-fade placement="bottomright" custom-class="w-25 text-justify custom-popover-local-notes">
                        <template #title>
                            {{ trans('sh-description-label', 314) }}
                        </template>
                        {{ cell.data.item.description }}
                    </b-popover>
                </template>

            </app-view-table>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
    </b-card>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';

import OffersShiftsQualificationsRelations from '@app/Planning/Offers/Shifts/PersonAdd/Components/OffersShiftsQualificationsRelations.vue';

export default {

    components: {
        OffersShiftsQualificationsRelations,
    },

    mounted() {
        this.onCreateThis();
    },

    computed: {

    },

    props: ["id_person_data_main"],

    data() {
        return {
            filters: {
                ids_shift_data_main: null,
                page: 1,
                per_page: 10,
                search: "",
                changed: 0,
                date_type: 1,
                offer_date: null,
                offer_week: null,
                location: '',
                ids_location_data_main: [],
                ids_department_data_main: [],
                ids_group_const_type: [],
                type: 6,
                filtering: {
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: {}, widthClass: 'app-width-min-350', refreshList: false },
                    client_departments: { type: 'list', changed: 0, values: [], function: "getDepartmentsShifts", parameters: { ids_client_data_main: [], ids_location_data_main: [] }, widthClass: 'app-width-min-350', refreshList: false },
                    functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass: 'app-width-min-350' },
                    dates: { type: 'dates', changed: 0, values: [], function: "", parameters: { type: null }, widthClass: 'app-width-min-350' },
                    shift_statuses: { type: 'list', changed: 0, values: [], function: "getShiftStatuses", parameters: null, widthClass: 'app-width-min-350' },
                    persons: { type: 'list', changed: 0, values: [], function: 'getFlexworkers', parameters: { id_settings_const_type: 6, list_type: 'shifts_list' }, widthClass: 'app-width-min-250' },
                    daytime_name: { type: 'list', changed: 0, values: [], function: "getShiftDaytime", parameters: null, widthClass: 'app-width-min-350' },
                    current_persons: { type: 'list', changed: 0, values: [], function: 'getCurrentFlexworkers', parameters: { id_settings_const_type: 12, list_type: 'shifts_list' }, widthClass: 'app-width-min-250', refreshList: false },
                },
                sorting: {
                    field_name: null,
                    sort_direction: 0,
                    changed: 0
                },
                shift_info: true,
                id_person_data_main: null,
                client_link: false
            },

            fields: [
                { key: "location_name", label: this.trans('location', 280), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "client_locations" },
                { key: "department", label: this.trans('department', 280), thStyle: { "min-width": "150px", "width": "100%" }, visible: true, filtering: true, filtering_name: "client_departments" },
                { key: "qualifications", label: 'KW', thStyle: { "min-width": "50px", "max-width": "50px" }, visible: true },
                { key: "date", label: this.trans('pl-date', 288), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "dates" },
                { key: "hour_from", label: this.trans('sh-begin', 292), thStyle: { "min-width": "70px", "max-width": "70px" }, visible: true, filtering: false },
                { key: "hour_to", label: this.trans('sh-end', 292), thStyle: { "min-width": "70px", "max-width": "70px" }, visible: true, filtering: false },
                { key: "daytime", label: this.trans('sh-type-of-service', 292), thStyle: { "min-width": "130px", "max-width": "130px" }, visible: true, filtering: true, filtering_name: 'daytime_name' },
                { key: "status_name", label: this.trans('status', 283), thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true },
                { key: "description", label: this.trans('td-description-short', 314), thStyle: { "min-width": "50px", "max-width": "150px" }, tdClass: "app-local-top-align app-local-row-center", visible: true, filtering: false, sortable: false },
                { key: "person_name", label: this.trans('flexworker', 280), thStyle: { "min-width": "220px", "max-width": "220px" }, visible: false, filtering: true, filtering_name: 'current_persons' },
            ],

            custom_columns: ['fw_buttons', 'person_name', 'shift_function_name', 'qualifications', 'description'],
            custom_headers: [],

            items: [],
            register_person_on_portal: JSON.parse(localStorage.getItem('user-portal')).register_person_on_portal,
            ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main).map(item => item.value),
            ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main).map(item => item.value),
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            loading: false,
            refresh: 0
        };
    },

    methods: {

        getList() {
            this.loading = true;
            this.filters.id_person_data_main = this.id_person_data_main;
            axios
                .post("shifts/getList", {
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                    this.checked_items = 0;
                    this.filters.ids_shift_data_main = null;
                });
        },

        setLocation() {
            if (!this.filters.client_link) {
                if (this.ids_location_data_main.length > 0) {
                    this.filters.filtering.client_locations.values = [];
                    this.filters.filtering.client_departments.parameters.ids_location_data_main = this.ids_location_data_main.map(item => item.value);
                }
            } else {
                this.filters.ids_location_data_main = [];
            }
        },

        async onCreateThis() {

            let url_params = new URLSearchParams(window.location.search);
            let url = new URL(window.location.href);

            if (url_params.has('type')) {
                this.filters.type = parseInt(url_params.get('type'));
                this.filters.offer_date = null;
                this.filters.offer_week = null;

                this.sidebar_filters.type = parseInt(url_params.get('type'));
                this.sidebar_filters.offer_date = null;
                this.sidebar_filters.offer_week = null;

            } else if (url.searchParams.has('id')) {
                this.filters.type = 3;
                this.filters.offer_date = null;
                this.filters.offer_week = null;

                this.sidebar_filters.type = 3;
                this.sidebar_filters.offer_date = null;
                this.sidebar_filters.offer_week = null;
                this.filters.ids_shift_data_main = url.searchParams.get('id')
            }

            this.filters.id_person_data_main = this.id_person_data_main;

            this.getList();

            this.$watch('filters.type', function (newVal, oldVal) {
                if (!this.loading) {
                    this.items = [];
                    this.getList();
                }
            });

            this.$watch('filters.location', function (newVal, oldVal) {
                if (!this.loading) {
                    if (newVal != null) {
                        this.fields[1].filtering = false;
                        this.filters.filtering.client_departments.parameters.ids_location_data_main = [newVal.value];
                    } else {
                        this.fields[1].filtering = true;
                        this.filters.filtering.client_departments.parameters.ids_location_data_main = [];
                    }
                    this.filters.filtering.client_departments.refreshList = true;
                    this.getList();
                }
            });

            this.$watch('filters.changed', function (newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                    this.filters.filtering.client_departments.refreshList = true;
                }
            });

            this.$watch('filters.filtering.client_locations.changed', function (newVal, oldVal) {
                if (!this.loading) {
                    this.filters.filtering.client_departments.parameters.ids_location_data_main = this.filters.filtering.client_locations.values;
                    this.filters.filtering.client_departments.refreshList = true;
                    this.getList();
                }
            });

            this.$watch('filters.filtering.client_departments.changed', function (newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                }
            });

            this.$watch('filters.filtering.dates.changed', function (newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                }
            });

            this.$watch('filters.filtering.daytime_name.changed', function (newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                }
            });

            this.$watch('filters.filtering.shift_statuses.changed', function (newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                }
            });

            this.$watch('filters.date_type', function (newVal, oldVal) {
                if (!this.loading) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    if (this.filters.date_type == 1) {
                        this.filters.offer_date = moment().format('DD-MM-YYYY');
                    } else if (this.filters.date_type == 2) {
                        this.filters.offer_week = moment().format('YYYY-WW');
                    }
                    this.getList();
                }
            });

            this.$watch('refresh', function (newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                }
            });

            this.$watch('filters.page', function (newVal, oldVal) {
                this.cheked_items = 0;
                if (!this.loading) {
                    this.getList();
                }
            });

            this.$watch('filters.search', function (newVal, oldVal) {
                if (!this.loading) {
                    this.filters.page = 1;
                    this.getList();
                }
            });

            this.$watch('filters.sorting.changed', function (newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                }
            });

        },
    },
};
</script>
<style>
.app-local-main-function {
    font-weight: 600;
    font-size: 13px;
}

.app-local-additional-function {
    font-weight: 300;
    font-size: 12px;
}

.app-local-sidebar-width .b-sidebar {
    width: 338px !important;
}

.app-local-fw-buttons {
    max-width: 230px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.custom-popover-header .popover-header {
    background-color: #f5faff !important;
}

.custom-popover-width {
    min-width: 10px !important;
    max-width: 25% !important;
    max-height: 75%;
    overflow-y: auto;
}

.custom-popover-local-notes {
    overflow-y: auto !important;
    max-height: 500px !important;
}
</style>
<style scoped>
.app-local-header-height {
    height: 32px;
}

.app-local-margin {
    position: absolute;
    top: 90vh;
    width: 100%;

}

.alert-color {
    color: #174066 !important;
}

.custom-avatar {
    height: 46px;
    width: 46px;
}

@media (min-width: 1405px) {
    .app-local-big {
        display: block !important;
    }

    .app-local-smart {
        display: none !important;
    }
}

@media (max-width: 1405px) {
    .app-local-big {
        display: none !important;
    }

    .app-local-smart {
        display: block !important;
    }
}
</style>